import * as React from 'react'
import {PageProps} from 'gatsby'
import {useQueryParam, StringParam} from 'use-query-params'
import {Layout, SiteConfigData} from '../../components/Layout'
import {ContentfulBlogPost, ContentfulBlogPostData} from '../../components/ContentfulBlogPost'
import {useContentful, transformEntry, PreviewPageContext} from '../../utils/preview'

export const PagePreview: React.FC<PageProps<unknown, PreviewPageContext>> = ({pageContext}) => {
  const [blogPostId] = useQueryParam('id', StringParam)
  const {useEntry: useWebsiteSpaceEntry} = useContentful(pageContext.contentfulWebsiteSpaceConfig)
  const {useEntry: useAppSpaceEntry} = useContentful(pageContext.contentfulAppSpaceConfig)
  const [layout, layoutIsLoading] = useWebsiteSpaceEntry(pageContext.contentfulSiteConfigEntryId)
  const [blogPost, blogPostIsLoading] = useAppSpaceEntry(blogPostId)

  if (blogPostIsLoading || layoutIsLoading) {
    return (
      <div className="container">
        <h1 className="is-size-1 has-text-centered">Loading...</h1>
      </div>
    )
  }

  const siteConfig = transformEntry<SiteConfigData>(layout)
  const blogPostData = transformEntry<ContentfulBlogPostData>(blogPost)

  return (
    <Layout isPreview data={siteConfig}>
      <ContentfulBlogPost path="" data={blogPostData} />
    </Layout>
  )
}

export default PagePreview
